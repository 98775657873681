/**
 * App Settings
 */
import Cookies from 'js-cookie';
//  import Icon from '../../components/icon';




var check = Cookies.get('night_mode_cookie_good_vibes');
if (check == "true") {
    var night_mode = true
}
else {
    var night_mode = false
}






const settings = {

    api_url : "https://api.goodvibeswithin.com:5006/v1/",   
    api_my_url : "https://api.goodvibeswithin.com:5006",

    // api_my_url: "http://192.168.1.116:5006",
    // api_url: "http://192.168.1.116:5006/v1/",


    night_mode: night_mode,
    spotlight_mode: false,
    show_section_lines: true,
    sidebar_dark: false,
    sidebar_static: false,
    sidebar_small: false,
    sidebar_effect: 'shrink',
    nav: true,
    nav_dark: false,
    // nav_logo_path: require( '../../common-assets/images/logo_company.png' ),
    // nav_logo_path: require( '../../common-assets/images/logo_new_data.jpg' ),
    // nav_logo_white_path: require( '../../common-assets/images/logo_company.png' ),
    // nav_logo_white_path: require( '../../common-assets/images/logo-white.PNG' ),
    nav_logo_width: '45px',
    nav_logo_url: '/questionnaire-users',
    nav_align: 'left',
    nav_expand: 'lg',
    nav_sticky: true,
    nav_autohide: true,
    nav_container_fluid: true,
    home_url: '/questionnaire-users',
    navigation: {
    },
    navigation_sidebar: {
        '/Booking': {

            name: 'Booking',
            icon: 'command',
            sub: {
                '/questionnaire-users': {
                    name: 'Unread Questionnaires',
                    show_badge: true
                },
                '/mentors-reports': {
                    name: 'Mentors',
                },
                '/courses-reports': {
                    name: 'Courses',
                },

            },
        },
        '/user-management': {
            name: 'User Management',
            icon: 'users',
        },
   
        // '/user-details': {
        //     name: 'User Details',
        //     icon: 'users',
        // },
   

        '/rituals': {
            name: 'Rituals',
            icon: 'codepen',
        },
        '/Mentors': {
            name: 'Mentors',
            icon: 'speaker',
        },
        '/Courses': {
            name: 'Courses',
            icon: 'columns',
        },
        '/guidelines': {
            name: 'Guidelines',
            icon: 'globe',
        },

        '/questionnaire-forms': {
            name: 'Questionnaire Forms',
            icon: 'file-text',
        },

        '/subscription-management': {
            name: 'Subscription Management',
            icon: 'slack',
        },

        '/admin-management': {
            name: 'Admin Management',
            icon: 'user',
        },

        

        '/Feedback': {
            name: 'Feedback',
            icon: 'message-circle',
        },
        '/billing': {
            name: 'Billing',
            icon: 'dollar-sign',
        },
        '/banner-management': {
            name: 'Banner Management',
            icon: 'image',
        },
        '/event-management': {
            name: 'Event Management',
            icon: 'award',
        },
        // '/RitualsBackup': {
        //     name: 'RitualsBackup',
        //     icon: 'message-circle',
        // },


    },
    breadcrumbs_presets: {
        apps: {
            '/mailbox': 'Mailbox',
            '/messenger': 'Messenger',
            '/calendar': 'Calendar',
            '/project-management': 'Project Management',
            '/file-manager': 'File Manager',
            '/profile': 'Profile',
        },
        content: {
            '/grid': 'Grid',
            '/colors': 'Colors',
            '/typography': 'Typography',
            '/component-table': 'Tables',
        },
        components_base: {
            '/component-alert': 'Alert',
            '/component-badge': 'Badge',
            '/component-breadcrumbs': 'Breadcrumbs',
            '/component-button': 'Button',
            '/component-card': 'Card',
            '/component-carousel': 'Carousel',
            '/component-collapse': 'Collapse',
            '/component-dropdown': 'Dropdown',
            '/component-list-group': 'List Group',
            '/component-media-object': 'Media Object',
            '/component-modal': 'Modal',
            '/component-nav': 'Nav',
            '/component-pagination': 'Pagination',
            '/component-popover': 'Popover',
            '/component-progress': 'Progress',
            '/component-spinner': 'Spinner',
            '/component-tabs': 'Tabs',
        },
        components_advanced: {
            '/component-icons-feather': 'Icons: Feather',
            '/component-icons-fontawesome': 'Icons: Font Awesome',
            '/component-charts-chartjs': 'Charts: Chart.js',
            '/component-charts-chartist': 'Charts: Chartist',
            '/component-charts-peity': 'Charts: Peity',
            '/component-charts-echarts': 'Charts: eCharts',
            '/component-charts-flot': 'Charts: Flot',
            '/component-timeline': 'Timeline',
            '/component-tree-view': 'Tree View',
            '/component-toast': 'Toast',
            '/component-sweet-alert': 'Sweet Alert',
            '/component-maps': 'Maps',
            '/component-data-tables': 'Data Tables',
        },
        forms_advanced: {
            '/forms-datetime': 'DateTime Picker',
            '/forms-validation': 'Validation',
            '/forms-touch-spin': 'Touch Spin',
            '/forms-range-slider': 'Range Slider',
            '/forms-input-masks': 'Input Masks',
            '/forms-dropzone': 'Dropzone',
            '/forms-colorpicker': 'Color Picker',
            '/forms-select': 'Select',
            '/forms-markdown': 'Markdown editor',
            '/forms-wysiwyg': 'WYSIWYG editor',
        },
    },
    img_country: {
        usa: require('../../common-assets/images/flags/united-states-of-america.svg'),
        china: require('../../common-assets/images/flags/china.svg'),
        germany: require('../../common-assets/images/flags/germany.svg'),
        japan: require('../../common-assets/images/flags/japan.svg'),
        spain: require('../../common-assets/images/flags/spain.svg'),
        france: require('../../common-assets/images/flags/france.svg'),
        canada: require('../../common-assets/images/flags/canada.svg'),
        netherlands: require('../../common-assets/images/flags/netherlands.svg'),
        italy: require('../../common-assets/images/flags/italy.svg'),
        russia: require('../../common-assets/images/flags/russia.svg'),
        czech_republic: require('../../common-assets/images/flags/czech-republic.svg'),
    },
    img_file: {
        empty: require('../../common-assets/images/icon-empty.svg'),
        zip: require('../../common-assets/images/icon-zip.svg'),
        rar: require('../../common-assets/images/icon-rar.svg'),
        html: require('../../common-assets/images/icon-html.svg'),
        php: require('../../common-assets/images/icon-php.svg'),
        css: require('../../common-assets/images/icon-css.svg'),
        js: require('../../common-assets/images/icon-js.svg'),
        doc: require('../../common-assets/images/icon-doc.svg'),
        txt: require('../../common-assets/images/icon-txt.svg'),
        pdf: require('../../common-assets/images/icon-pdf.svg'),
        xls: require('../../common-assets/images/icon-xls.svg'),
        png: require('../../common-assets/images/icon-png.svg'),
        jpg: require('../../common-assets/images/icon-jpg.svg'),
    },
    users: [
        {
            // img: require( '../../common-assets/images/avatar-1.png' ),
            // img_profile: require( '../../common-assets/images/avatar-1-profile.png' ),
            // name: 'Jack Boyd',
            img: require('../../common-assets/images/avatar-1.png'),
            // img_profile: require( '../../common-assets/images/logo_company.png' ),
            name: 'Company Logo',
        }, {
            img: require('../../common-assets/images/avatar-2.png'),
            name: 'Helen Holt',
        }, {
            img: require('../../common-assets/images/avatar-3.png'),
            name: 'Avice Harris',
        }, {
            img: require('../../common-assets/images/avatar-4.png'),
            name: 'Anna Rice',
        }, {
            img: require('../../common-assets/images/avatar-5.png'),
            name: 'Amber Smith',
        }, {
            img: require('../../common-assets/images/avatar-6.png'),
            name: 'Mary Rose',
        },
    ],
    letters: [
        {
            img: require('../../common-assets/images/letter-1.png'),
            img_min: require('../../common-assets/images/letter-1-min.png'),
        }, {
            img: require('../../common-assets/images/letter-2.png'),
            img_min: require('../../common-assets/images/letter-2-min.png'),
        }, {
            img: require('../../common-assets/images/letter-3.png'),
            img_min: require('../../common-assets/images/letter-3-min.png'),
        }, {
            img: require('../../common-assets/images/letter-4.png'),
            img_min: require('../../common-assets/images/letter-4-min.png'),
        },
    ],
};

/* Parse GET variables to change initial settings */
const $_GET = {};
window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (a, name, value) => {
    $_GET[name] = value;
});

Object.keys($_GET).forEach((name) => {
    const isTrue = $_GET[name] === '1';

    switch (name) {
        case 'setting-night-mode':
            settings.night_mode = isTrue;
            break;
        case 'setting-show-section-lines':
            settings.show_section_lines = isTrue;
            break;
        case 'setting-sidebar-small':
            settings.sidebar_small = isTrue;
            break;
        case 'setting-sidebar-dark':
            settings.sidebar_dark = isTrue;
            break;
        case 'setting-nav-dark':
            settings.nav_dark = isTrue;
            break;
        // no default
    }
});

export default settings;
